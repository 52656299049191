import { Link } from "gatsby"
import * as React from "react"
import parse from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import { Button, Container, Row, Col, Form } from "react-bootstrap"
// import deskbanner from "../../../images/offplan/desk-banner.png";
import "./FormBlock.scss"

import GetStarted from "../../forms/get-started"
import Newsletter from "../../forms/newsletter"
import SignUp from "../../forms/signup"
import InstantValuation from "../../forms/instant-valuation-form"
import Postcode from "../../forms/postcode-search-form"
// markup
const FormBlock = (props) => {
    const renderForm = (select_form) => {
        if (select_form == 'Postcode_Only') {
            return (<GetStarted />)
        } else if (select_form == 'Newsletter_Subscribe') {
            return (<Newsletter />)
        } else if (select_form == 'Instant_Valuation') {
            return (<InstantValuation />)
        } else if (select_form == 'Sign_Up') {
            return (<Newsletter />)
        } else {
            return (<GetStarted />)
        }
    }

    return (
        <React.Fragment>
            <section className={`form-section components--Home--FormBlock--FormBlock ${props.Show_Chart ? 'piechart' : ''} ${props.Select_Form?.Select == "Newsletter_Subscribe" ? "newsletter-subscribe-help" : ""}`}>
                <Container>
                    <Row className="main-row">
                        <Col lg="12">
                            <div className="register-interest-form-block">
                                <div className="valuation-block">
                                    {props.Content &&
                                        <>
                                            {parse(props.Content)}
                                        </>
                                    }
                                </div>
                                <div className="register-interest-form">
                                    {
                                        renderForm(props.Select_Form?.Select)
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default FormBlock
